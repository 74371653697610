import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '@/app-fsd/providers/StoreProvider';
import urls from '@/shared/const/urls';

export const getNewUser = createAsyncThunk<
  boolean,
  number,
  ThunkConfig<string>
>('user/getNewUser', async (id, thunkApi) => {
  const { extra, rejectWithValue } = thunkApi;

  try {
    const response = await extra.api.get<{ passwordExpired: boolean }>(
      urls.user.newUser(id),
    );

    if (!response.data) throw new Error();

    return response.data.passwordExpired;
  } catch (error) {
    console.log(error);
    return rejectWithValue('ERROR');
  }
});
