import { FC, memo } from 'react';
import { HStack } from '../../../Stack';
import clsInput from '../../model/styles/inputStyles.module.css';
import { IErrorBlockInputs } from '../../model/types';
import { classNames } from '@/shared/lib/classNames/classNames';

export const ErrorBlock: FC<IErrorBlockInputs> = memo(props => {
  const { error, isError, setError, errorClass, errorIcon, name } = props;

  return (
    <HStack
      gap='x-x-s'
      align='start'
      className={classNames(clsInput['error-text'], {}, [errorClass])}
    >
      {!!(!!error || isError) && errorIcon}
      <span data-testid={`Input.Error.${name}`}>{error}</span>
    </HStack>
  );
});
