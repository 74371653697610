import { StateSchema } from '@/app-fsd/providers/StoreProvider';

export const getUserIsLoading = (state: StateSchema) =>
  state.user.isLoadingUser;

export const getUserError = (state: StateSchema) => state.user?.errorUser;

export const getDataUser = (state: StateSchema) => state.user?.userData;

export const getGeolocationUser = (state: StateSchema) =>
  state.user?.userData?.geolocation;

export const getIdUser = (state: StateSchema) => state.user?.userData?.id;

export const getProfileUser = (state: StateSchema) => state.user.profile;

export const getUserName = (state: StateSchema) => state.user?.userData?.name;

export const getIsNewUser = (state: StateSchema) => state.user?.isNewUser;

// PA
export const getPATransactionsIsLoading = (state: StateSchema) =>
  state.transactionsPA.isLoading;

export const getPATransactionsError = (state: StateSchema) =>
  state.transactionsPA.error;

export const getPATransactionsData = (state: StateSchema) =>
  state.transactionsPA.data;

export const getPATransactionsCount = (state: StateSchema) =>
  state.transactionsPA.count;

export const getPATransactionsTotal = (state: StateSchema) =>
  state.transactionsPA.total;
export const getPATransactionsForRequest = (state: StateSchema) =>
  state.transactionsPA.forRequest;
export const getPATransactionsPage = (state: StateSchema) =>
  state.transactionsPA.forRequest.page;
export const getPATransactionsSize = (state: StateSchema) =>
  state.transactionsPA.forRequest.size;
