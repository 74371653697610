import { CountryCode } from 'libphonenumber-js/types';
import { useTranslations } from 'next-intl';
import { FC, KeyboardEvent, memo, useCallback } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import cls from './phoneInputMask.module.css';
import { VStack } from '../../../Stack';
import clsInput from '../../model/styles/inputStyles.module.css';
import { IInputProps } from '../../model/types';
import { ErrorBlock } from '../ErrorBlock/ErrorBlock';
import { classNames } from '@/shared/lib/classNames/classNames';
import { debounce } from '@/shared/lib/helpers/debounce';
import {
  IValidateInputsProps,
  validateInputs,
} from '@/shared/lib/helpers/validateInputs';

interface IPhoneInputProps extends IInputProps {
  classNameWrapper?: string;
  geoLocation: CountryCode;
}

export const PhoneInputMask: FC<IPhoneInputProps> = memo(props => {
  const {
    className,
    value,
    onChange,
    isValidate = false,
    type = 'tel',
    label,
    fullWidth = true,
    min,
    max,
    error,
    isError,
    setError,
    errorIcon,
    errorClass,
    children,
    required,
    classNameWrapper,
    name,
    geoLocation,
    id,
    size,
    disabled,
    placeholder = 'Enter phone number',
    ...otherProps
  } = props;

  const t = useTranslations();

  const handleValidate = useCallback(
    (newValue: string): any => {
      const options: IValidateInputsProps = { max, min, required, t, type };
      setError?.(validateInputs(newValue, options));
    },
    [max, min, required, setError, type, t],
  );

  const onChangeHandler = useCallback(
    (val?: string) => {
      if (val) {
        onChange?.(val.trim());
        if (isValidate) debounce(handleValidate(val.trim()));
      }
    },
    [handleValidate, isValidate, onChange],
  );

  const activatedTrimParameters = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ' ') event.preventDefault();
  };

  const modsWrapper = {
    [clsInput['full-width']]: fullWidth,
  };

  const modsInput = {
    [clsInput.error]: !!error || isError,
    [clsInput['full-width']]: fullWidth,
  };

  return (
    <VStack className={classNames('', modsWrapper, [classNameWrapper])}>
      {!!label && (
        <label htmlFor={label || id || 'input'} className={clsInput.label}>
          {/* {!!required && <span className={clsInput.required}>{'*'}</span>} */}
          {label}
        </label>
      )}
      <VStack
        // gap='x-s'
        className={classNames(clsInput.container, modsWrapper, [
          cls['phone-input-container'],
        ])}
      >
        <PhoneInput
          international
          style={{ height: 45 }}
          defaultCountry={geoLocation}
          placeholder={placeholder}
          id={label || id || 'input'}
          autoComplete='off'
          type={type}
          className={classNames(clsInput.input, modsInput, [className])}
          value={value as string}
          name={name}
          required={required}
          disabled={disabled}
          onWheel={(e: any) => e.target.blur()}
          onChange={onChangeHandler}
          onKeyDown={activatedTrimParameters}
          {...otherProps}
        />
        {children}
        {!!(isValidate || required) && (
          <ErrorBlock
            errorClass={errorClass}
            error={error}
            isError={isError}
            errorIcon={errorIcon}
            name={name}
          />
        )}
      </VStack>
    </VStack>
  );
});
