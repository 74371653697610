import { createAsyncThunk } from '@reduxjs/toolkit';
import { IUser } from '../types/user';
import { ThunkConfig } from '@/app-fsd/providers/StoreProvider';
import urls from '@/shared/const/urls';

interface ISignInUserReqProps {
  email: string;
  password: string;
}

export const signInUserReq = createAsyncThunk<
  IUser,
  ISignInUserReqProps,
  ThunkConfig<string>
>('user/signIn', async (signInUserData, thunkApi) => {
  const { extra, rejectWithValue } = thunkApi;

  try {
    const response = await extra.api.post<IUser>(
      urls.user.signIn,
      signInUserData,
    );
    if (!response.data) throw new Error();

    return response.data;
  } catch (error) {
    console.log(error);
    return rejectWithValue('ERROR');
  }
});
