import { CSSProperties, FC, HTMLAttributes, memo } from 'react';
import cls from './circleSpinner.module.css';

interface ISpinnerProps extends HTMLAttributes<HTMLDivElement> {
  color?: string;
  secondaryColor?: string;
  enabled?: boolean;
  size?: number | string;
  speed?: number;
  weightBorder?: number;
}

export const CircleSpinner: FC<ISpinnerProps> = memo(props => {
  const {
    color = 'transparent',
    secondaryColor = 'black',
    speed = 1.2,
    enabled = true,
    size = 22,
    style,
    weightBorder = 1,
  } = props;

  const currentStyles: CSSProperties = {
    animationDuration: `${speed}s`,
    borderColor: secondaryColor,
    borderRightColor: color,
    borderWidth: `${weightBorder}px`,
    height: size,
    width: size,
    ...style,
  };

  if (!enabled) return null;

  return <div className={cls.spinner} style={currentStyles} />;
});
