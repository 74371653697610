import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getGeolocationReq } from '../services/getGeolocationReq';
import { getNewUser } from '../services/getNewUser';
import { getProfileUserRequest } from '../services/getProfileUserRequest';
import { signInUserReq } from '../services/signInUserReq';
import { signUpUserReq } from '../services/signUpUserReq';
import { IUser, UserSchema, IErrorsUser, IProfileUser } from '../types/user';
import { NewLocation } from '@/shared/types/langTypes';

const dataError: IErrorsUser = {
  errorEmail: '',
  errorName: '',
  errorPhoneNumber: '',
};

const initialState: UserSchema = {
  errorUser: '',
  isLoadingUser: false,
  isNewUser: { passwordExpired: false },
  profile: { id: -1, lang: 'ru' },
  userData: {},
};

export const userSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(getProfileUserRequest.pending, state => {
        state.errorUser = undefined;
        state.isLoadingUser = true;
      })
      .addCase(
        getProfileUserRequest.fulfilled,
        (state, { payload }: PayloadAction<IProfileUser>) => {
          state.isLoadingUser = false;
          state.profile = payload;
        },
      )
      .addCase(getProfileUserRequest.rejected, (state, { payload }) => {
        state.isLoadingUser = false;
        state.errorUser = payload;
      })

      // signUpUser
      .addCase(signUpUserReq.pending, state => {
        state.errorUser = undefined;
        state.isLoadingUser = true;
      })
      .addCase(
        signUpUserReq.fulfilled,
        (state, { payload }: PayloadAction<IUser>) => {
          state.isLoadingUser = false;
          state.userData = payload;
        },
      )
      .addCase(signUpUserReq.rejected, (state, { payload }) => {
        state.isLoadingUser = false;
        state.errorUser = payload;
      })

      // signInUser
      .addCase(signInUserReq.pending, state => {
        state.errorUser = undefined;
        state.isLoadingUser = true;
      })
      .addCase(
        signInUserReq.fulfilled,
        (state, { payload }: PayloadAction<IUser>) => {
          state.isLoadingUser = false;
          state.userData = payload;
        },
      )
      .addCase(signInUserReq.rejected, (state, { payload }) => {
        state.isLoadingUser = false;
        state.errorUser = payload;
      })

      // get geolocation
      .addCase(getGeolocationReq.pending, state => {
        state.errorUser = undefined;
        state.isLoadingUser = true;
      })
      .addCase(
        getGeolocationReq.fulfilled,
        (state, { payload }: PayloadAction<NewLocation>) => {
          state.isLoadingUser = false;
          state.userData = {
            ...state.userData,
            geolocation: payload.country.iso_code,
          };
        },
      )
      .addCase(getGeolocationReq.rejected, (state, { payload }) => {
        state.isLoadingUser = false;
        state.errorUser = payload;
      })

      // getNewUser
      .addCase(getNewUser.pending, state => {
        state.errorUser = undefined;
        state.isLoadingUser = true;
      })
      .addCase(
        getNewUser.fulfilled,
        (state, { payload }: PayloadAction<boolean>) => {
          state.isLoadingUser = false;
          state.isNewUser.passwordExpired = payload;
        },
      )
      .addCase(getNewUser.rejected, (state, { payload }) => {
        state.isLoadingUser = false;
        state.errorUser = payload;
      });
  },
  initialState,
  name: 'user',
  reducers: {
    setDataUser: (state, { payload }: PayloadAction<IUser>) => {
      state.userData = { ...state.userData, ...payload };
    },
    setErrorUser: (state, { payload }: PayloadAction<string>) => {
      state.errorUser = payload;
    },
    setIsLoadingUser: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingUser = payload;
    },
    setOneFieldDataUser: (
      state,
      { payload }: PayloadAction<{ [key: string]: IUser[keyof IUser] }>,
    ) => {
      state.userData = { ...state.userData, ...payload };
    },
    setProfileUser: (state, { payload }: PayloadAction<IProfileUser>) => {
      state.profile = payload;
    },
  },
});

export const {
  actions: {
    setDataUser,
    setErrorUser,
    setIsLoadingUser,
    setOneFieldDataUser,
    setProfileUser,
  },
} = userSlice;

export const { reducer: userReducer } = userSlice;
