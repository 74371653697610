import { Combobox } from '@headlessui/react';
import { useState, FC, memo, useMemo, Fragment, useEffect } from 'react';
import cls from './autocomplete.module.css';
import popupCls from '../../../../styles/popups.module.css';
import { Icon } from '../../../Icon';
import clsInput from '../../model/styles/inputStyles.module.css';
import { AutocompleteProps } from '../../model/types';
import logo from '@/shared/assets/svg/logo.svg';
import { classNames } from '@/shared/lib/classNames/classNames';
import { mapMarginsSize } from '@/shared/styles/const';

export const Autocomplete: FC<AutocompleteProps> = memo(props => {
  const {
    value,
    className,
    classNamePopup,
    onChange,
    items,
    label,
    placeholder,
    fullWidth,
    size = 'default',
    minWidth = 'x-s',
  } = props;

  const [selected, setSelected] = useState(value);
  const [valueInput, setValueInput] = useState(value);

  const filteredPeople = useMemo(
    () =>
      valueInput !== ''
        ? items.filter(item =>
            item
              .toLowerCase()
              .replace(/\s+/g, '')
              // @ts-ignore
              .includes(valueInput?.toLowerCase().replace(/\s+/g, '')),
          )
        : items,
    [items, valueInput],
  );

  const onChangeHandler = (newItem: string) => {
    setSelected(newItem);
    setValueInput(newItem);
    onChange?.(newItem);
  };

  const modsInput = {
    [clsInput['full-width']]: fullWidth,
  };

  useEffect(() => {
    if (typeof value === 'string') {
      setSelected(value);
      setValueInput(value);
    }
  }, [value]);

  return (
    <Combobox value={selected} onChange={onChangeHandler}>
      {({ open }) => (
        <div
          className={classNames(
            clsInput.container,
            { [clsInput['full-width']]: fullWidth },
            [],
          )}
        >
          <Combobox.Button className={cls.button} as='div'>
            {!!label && (
              <label htmlFor='input' className={clsInput.label}>
                {label}
              </label>
            )}
            <Combobox.Input
              className={classNames(clsInput.input, modsInput, [className])}
              id='input'
              displayValue={(item: string) => item}
              placeholder={placeholder}
              value={valueInput}
              style={{ minWidth: `${mapMarginsSize[minWidth]}rem` }}
              onChange={event => setValueInput(event.target.value)}
            />
            <div className={clsInput['end-icon']}>
              {open ? <Icon Svg={logo} /> : <Icon Svg={logo} />}
            </div>
          </Combobox.Button>
          <Combobox.Options
            className={classNames(popupCls.menu, {}, [classNamePopup])}
            as='div'
            style={{ minWidth: `${mapMarginsSize[minWidth]}rem` }}
          >
            {filteredPeople.length === 0 && valueInput !== '' ? (
              <div>{'Nothing found.'}</div>
            ) : (
              filteredPeople.map(item => (
                <Combobox.Option key={item} value={item} as={Fragment}>
                  {({ selected: selectedItem, active }) => (
                    <div
                      className={classNames(
                        popupCls.item,
                        {
                          [popupCls.active]: active,
                          [popupCls.selected]: selectedItem,
                        },
                        [popupCls[size]],
                      )}
                    >
                      {item}
                    </div>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </div>
      )}
    </Combobox>
  );
});
