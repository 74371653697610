import { createAsyncThunk } from '@reduxjs/toolkit';
import { IUser } from '../types/user';
import { ThunkConfig } from '@/app-fsd/providers/StoreProvider';
import urls from '@/shared/const/urls';

export const resendUserPasswordReq = createAsyncThunk<
  IUser,
  string,
  ThunkConfig<string>
>('user/resendPassword', async (email, thunkApi) => {
  const { extra, rejectWithValue } = thunkApi;

  try {
    const response = await extra.api.post<IUser>(urls.user.resendPassword, {
      email,
    });
    if (!response.data) throw new Error();

    return response.data;
  } catch (error) {
    console.log(error);
    return rejectWithValue('ERROR');
  }
});
