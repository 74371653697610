import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { pATransactions } from '../services/pATransactions';
import {
  IPATransactionsSchema,
  IPATransactionsResponse,
  IPATransactionsData,
} from '../types/user';

const initialState: IPATransactionsSchema = {
  count: 0,
  data: [],
  error: '',
  forRequest: {
    page: 1,
    search: '',
    size: 20,
    sort: {
      direction: 'DESC',
      name: 'updatedAt',
    },
  },
  isLoading: false,
  total: 0,
};

export const transactionsPASlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(pATransactions.pending, state => {
        state.error = undefined;
        state.isLoading = true;
      })
      .addCase(
        pATransactions.fulfilled,
        (state, { payload }: PayloadAction<IPATransactionsResponse>) => {
          state.isLoading = false;
          state.data = payload.data;
          state.count = payload.count;
          state.total = payload.total;
        },
      )
      .addCase(pATransactions.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      });
  },
  initialState,
  name: 'transactionsPA',
  reducers: {
    setPATransactionsCount: (state, { payload }: PayloadAction<number>) => {
      state.count = payload;
    },
    setPATransactionsData: (
      state,
      { payload }: PayloadAction<IPATransactionsData[]>,
    ) => {
      state.data = payload;
    },
    setPATransactionsError: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    setPATransactionsIsLoading: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isLoading = payload;
    },
    setPATransactionsPage: (state, { payload }: PayloadAction<number>) => {
      state.forRequest.page = payload;
    },
    setPATransactionsTotal: (state, { payload }: PayloadAction<number>) => {
      state.total = payload;
    },
  },
});

export const {
  actions: {
    setPATransactionsCount,
    setPATransactionsData,
    setPATransactionsError,
    setPATransactionsPage,
    setPATransactionsIsLoading,
    setPATransactionsTotal,
  },
} = transactionsPASlice;

export const { reducer: transactionsPAReducer } = transactionsPASlice;
