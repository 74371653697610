import { createAsyncThunk } from '@reduxjs/toolkit';
import { IUser } from '../types/user';
import { ThunkConfig } from '@/app-fsd/providers/StoreProvider';
import urls from '@/shared/const/urls';
import { showSnackbar } from '@/shared/ui/Snackbars/Snackbars';

interface IChangeUserPasswordReqProps {
  email: string;
  oldPassword: string;
  newPassword: string;
  lang?: string;
}

export const changeUserPasswordReq = createAsyncThunk<
  IUser,
  IChangeUserPasswordReqProps,
  ThunkConfig<string>
>('user/changePassword', async (changePasswordData, thunkApi) => {
  const { extra, rejectWithValue } = thunkApi;

  const toastMsg =
    changePasswordData.lang === 'ru'
      ? 'Данные были обновлены'
      : 'The data has been updated';

  try {
    const response = await extra.api.patch<IUser>(
      urls.user.changePassword,
      changePasswordData,
    );

    changePasswordData.lang &&
      showSnackbar(toastMsg, 'success', changePasswordData.lang);

    if (!response.data) throw new Error();

    return response.data;
  } catch (error: any) {
    console.log(error);
    showSnackbar(error?.response?.data?.message, 'error');
    return rejectWithValue('ERROR');
  }
});
