import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '@/app-fsd/providers/StoreProvider';
import urls from '@/shared/const/urls';
import { showSnackbar } from '@/shared/ui/Snackbars/Snackbars';

interface IRemoveUser {
  reason: string;
  lang: string;
}

export const removeUser = createAsyncThunk<
  void,
  IRemoveUser,
  ThunkConfig<string>
>('user/removeUser', async ({ reason, lang }, thunkApi) => {
  const { extra, rejectWithValue } = thunkApi;

  const toastMsg =
    lang === 'ru' ? 'Ваш аккаунт удален' : 'Your account has been deleted';

  try {
    const response = await extra.api.delete(urls.user.users, {
      data: {
        reason,
      },
    });

    sessionStorage.removeItem('persist:root');
    localStorage.removeItem('persist:root');

    lang && showSnackbar(toastMsg, 'success', lang);

    if (!response.data) throw new Error();

    return response.data;
  } catch (e) {
    console.log(e);
    return rejectWithValue('error');
  }
});
