import { useTranslations } from 'next-intl';
import {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import cls from './Input.module.css';
import { HStack, VStack } from '../../../Stack';
import clsInput from '../../model/styles/inputStyles.module.css';
import { IInputProps } from '../../model/types';
import { ErrorBlock } from '../ErrorBlock/ErrorBlock';
import { classNames } from '@/shared/lib/classNames/classNames';
import { debounce } from '@/shared/lib/helpers/debounce';
import {
  IValidateInputsProps,
  validateInputs,
} from '@/shared/lib/helpers/validateInputs';

export const Input: FC<IInputProps> = props => {
  const {
    className,
    value = '',
    onChange,
    type = 'text',
    fullWidth = true,
    isValidate = false,
    label,
    autofocus,
    min,
    max,
    error,
    isError,
    setError,
    startIcon,
    endIcon,
    afterIcon,
    children,
    endIconClass,
    classNameWrapper,
    errorClass,
    errorIcon,
    id,
    name,
    required,
    size,
    disabled,
    ...otherProps
  } = props;

  const t = useTranslations();
  const ref = useRef<HTMLInputElement>(null);

  const handleValidate = useCallback(
    (newValue: string): any => {
      const options: IValidateInputsProps = {
        max,
        min,
        name,
        required,
        t,
        type,
      };
      setError?.(validateInputs(newValue, options));
    },
    [max, min, required, setError, type, t, name],
  );

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const targetValue = event.target.value;

      onChange?.(
        targetValue.startsWith(' ') ? targetValue.trim() : targetValue,
      );
      if (isValidate) debounce(handleValidate(targetValue));
    },
    [handleValidate, isValidate, onChange],
  );

  const activatedTrimParameters = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ' ') event.preventDefault();
  };

  useEffect(() => {
    if (autofocus) ref.current?.focus();
  }, [autofocus]);

  const modsWrapper = {
    [clsInput['full-width']]: fullWidth,
  };

  const modsContainer = {
    [clsInput['full-width']]: fullWidth,
    [cls['after-icon']]: !!afterIcon,
  };

  const modsInput = {
    [clsInput.error]: !!error || isError,
    [clsInput['full-width']]: fullWidth,
    [cls['padding-left']]: !!startIcon,
    [cls['padding-right']]: !!endIcon,
  };

  return (
    <VStack className={classNames('', modsWrapper, [classNameWrapper])}>
      {!!label && (
        <label htmlFor={label || id || 'input'} className={clsInput.label}>
          {/* {!!required && <span className={clsInput.required}>{'*'}</span>} */}
          {label}
        </label>
      )}
      <VStack
        // gap='x-s'
        className={classNames(clsInput.container, modsContainer, [])}
      >
        <input
          ref={ref}
          id={label || id || 'input'}
          autoComplete='off'
          type={type}
          className={classNames(clsInput.input, modsInput, [className])}
          value={value}
          name={name}
          required={required}
          disabled={disabled}
          onWheel={(e: any) => e.target.blur()}
          onChange={onChangeHandler}
          // onKeyDown={activatedTrimParameters}
          {...otherProps}
        />
        {!!startIcon && (
          <HStack className={clsInput['start-icon']}>{startIcon}</HStack>
        )}
        {!!endIcon && (
          <div className={classNames(clsInput['end-icon'], {}, [endIconClass])}>
            {endIcon}
          </div>
        )}
        {afterIcon}
        {children}
        {!!(isValidate || required) && (
          <ErrorBlock
            errorClass={errorClass}
            error={error}
            isError={isError}
            errorIcon={errorIcon}
            name={name}
          />
        )}
      </VStack>
    </VStack>
  );
};
