import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPATransactionsRequest, IPATransactionsResponse } from '../types/user';
import { ThunkConfig } from '@/app-fsd/providers/StoreProvider';
import urls from '@/shared/const/urls';

export const pATransactions = createAsyncThunk<
  IPATransactionsResponse,
  IPATransactionsRequest,
  ThunkConfig<string>
>('user/transactionsPA', async (data, thunkApi) => {
  const { extra, rejectWithValue } = thunkApi;

  try {
    const response = await extra.api.post<IPATransactionsResponse>(
      urls.user.transactions,
      data,
    );
    if (!response.data) throw new Error();

    return response.data;
  } catch (error) {
    console.log(error);
    return rejectWithValue('ERROR');
  }
});
