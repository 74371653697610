import { createAsyncThunk } from '@reduxjs/toolkit';
import { IProfileUser } from '../types/user';
import { ThunkConfig } from '@/app-fsd/providers/StoreProvider';
import urls from '@/shared/const/urls';
import { showSnackbar } from '@/shared/ui/Snackbars/Snackbars';

interface IPatchUserProfile {
  id: number;
  lang?: string;
  name?: string;
  phoneNumber?: string;
}

export const patchUserProfile = createAsyncThunk<
  IProfileUser,
  IPatchUserProfile,
  ThunkConfig<string>
>('user/patchUserProfile', async (data, thunkApi) => {
  const { extra, rejectWithValue } = thunkApi;

  const toastMsg =
    data.lang === 'ru' ? 'Данные были обновлены' : 'The data has been updated';

  try {
    const response = await extra.api.patch<IProfileUser>(
      urls.user.getProfileUser,
      {
        id: data.id,
        lang: data.lang,
        name: data.name,
        phoneNumber: data.phoneNumber,
      },
    );

    data.lang && showSnackbar(toastMsg, 'success', data.lang);

    if (!response.data) throw new Error(response as any);

    return response.data;
  } catch (error: any) {
    console.log(error);
    showSnackbar(error?.response?.data?.message, 'error');
    return rejectWithValue(error?.response?.data?.message);
  }
});
