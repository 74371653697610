const urls = {
  invoices: {
    checkInvoiceStatus: (id: string) => `invoices/status/${id}`,
    createKunaPayInvoice: 'kunapay/invoices',
    getPaymentUrlYookassa: 'yookassa/payments',
  },
  location: (locationToken?: string) =>
    `https://api.geoapify.com/v1/ipinfo?&apiKey=${locationToken}`,
  markets: {
    getDiscountPackages: 'markets/products/promo',
    getMarkets: 'web/markets',
  },
  partners: {
    getAvailableCashout: 'web/partners/requests/available',
    getDetails: 'web/partners/details',
    getPaymentsRequest: 'web/partners/requests',
    getPaymentsUsers: 'web/partners/users',
    postCashoutRequest: 'web/partners/requests/create',
    refreshToken: 'web/partners/auth/refresh',
    resendPassword: 'web/partners/auth/password/resend',
    setPermanentPassword: 'web/partners/auth/password',
    signIn: 'web/partners/auth/login',
    signUp: 'web/partners/auth/register',
  },
  products: { getProductById: (id: number) => `products/${id}` },
  promo: {
    validatePromoCode: 'promoCodes/check',
  },
  stripe: {
    getClientSecretByCustomer: ({
      utmCampaign,
      utmContent,
      utmMedium,
      utmSource,
      utmTerm,
    }: {
      utmSource: {};
      utmMedium: {};
      utmCampaign: {};
      utmContent: {};
      utmTerm: {};
    }) =>
      `stripe/subscriptions/?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}&utm_content=${utmContent}&utm_term=${utmTerm}`,
  },
  subscription: {
    disableDD: (id: string) =>
      `web/users/subscriptions/${id}/direct-debit/cancel`,
    getAllActiveSubscriptions: 'web/users/subscriptions',
  },
  user: {
    changePassword: 'web/users/auth/password',
    getProfileUser: 'web/users/profile',
    getUserById: (userId: number) => `users/${userId}/payer`,
    newUser: (id: number) => `/web/users/auth/${id}/details`,
    packages: 'web/users/products',
    packagesAllowed: 'web/users/products/allowed',
    postUsers: 'users',
    refreshToken: 'web/users/auth/refresh',
    resendPassword: 'web/users/auth/password/resend',
    signIn: 'web/users/auth/login',
    signUp: 'web/users/auth/register',
    transactions: 'web/users/transactions',
    users: 'web/users',
  },
};

export default urls;
