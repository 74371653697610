import { useTranslations } from 'next-intl';
import {
  ChangeEvent,
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import cls from './textarea.module.css';
import { VStack } from '../../../Stack';
import clsInput from '../../model/styles/inputStyles.module.css';
import { ITextAreaProps } from '../../model/types';
import { classNames } from '@/shared/lib/classNames/classNames';
import { debounce } from '@/shared/lib/helpers/debounce';
import {
  IValidateInputsProps,
  validateInputs,
} from '@/shared/lib/helpers/validateInputs';

export const TextArea: FC<ITextAreaProps> = memo(props => {
  const {
    className,
    value,
    onChange,
    autofocus,
    label,
    fullWidth = true,
    rows = 4,
    error,
    isError,
    setError,
    required,
    isValidate,
    errorClass,
    errorIcon,
    name,
    ...otherProps
  } = props;

  const ref = useRef<HTMLTextAreaElement>(null);
  const t = useTranslations();

  const handleValidate = useCallback(
    (newValue: string): any => {
      const options: IValidateInputsProps = { required, t, type: 'text' };
      setError?.(validateInputs(newValue, options));
    },
    [required, setError, t],
  );

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const targetValue = event.target.value;

      onChange?.(
        targetValue.startsWith(' ') ? targetValue.trim() : targetValue,
      );
      if (isValidate) debounce(handleValidate(event.target.value.trim()));
    },
    [handleValidate, isValidate, onChange],
  );

  useEffect(() => {
    if (autofocus) ref.current?.focus();
  }, [autofocus]);

  const modsWrapper = useMemo(
    () => ({
      [clsInput['full-width']]: fullWidth,
      [clsInput.error]: !!error || isError,
    }),
    [error, fullWidth, isError],
  );

  const currentClassName = useMemo(
    () =>
      classNames(
        cls.textarea,
        { ...modsWrapper, [cls.error]: !!error || isError },
        [clsInput.input, className],
      ),
    [className, error, isError, modsWrapper],
  );

  return (
    <VStack gap='x-s' className={classNames('', modsWrapper, [])}>
      {!!label && (
        <label htmlFor='textarea' className={clsInput.label}>
          {label}
        </label>
      )}
      <textarea
        ref={ref}
        id='textarea'
        rows={rows}
        value={value}
        className={currentClassName}
        required={required}
        name={name}
        onChange={onChangeHandler}
        {...otherProps}
      />
      {/* TODO styles for error block here */}
      {/* {!!(isValidate || required) && (
        <ErrorBlock
          errorClass={errorClass}
          error={error}
          isError={isError}
          errorIcon={errorIcon}
          name={name}
        />
      )} */}
    </VStack>
  );
});
