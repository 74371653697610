import { createAsyncThunk } from '@reduxjs/toolkit';
import { IProfileUser } from '../types/user';
import { ThunkConfig } from '@/app-fsd/providers/StoreProvider';
import urls from '@/shared/const/urls';

export const getProfileUserRequest = createAsyncThunk<
  IProfileUser,
  void,
  ThunkConfig<string>
>('user/getProfileUserRequest', async (_, thunkApi) => {
  const { dispatch, extra, getState, rejectWithValue } = thunkApi;

  try {
    const response = await extra.api.get<IProfileUser>(
      urls.user.getProfileUser,
    );

    if (!response.data) throw new Error();

    return response.data;
  } catch (error) {
    console.log(error);
    return rejectWithValue('ERROR');
  }
});
