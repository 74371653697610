import {
  ButtonHTMLAttributes,
  FC,
  ReactNode,
  SVGProps,
  memo,
  useMemo,
} from 'react';
import cls from './Button.module.css';
import { Icon } from '../../../Icon';
import { CircleSpinner } from '../../../Loaders';
import { Text, Variants } from '../../../Text';
import { classNames } from '@/shared/lib/classNames/classNames';

type TypeButton =
  | 'accent'
  | 'primary'
  | 'secondary'
  | 'link'
  | 'accent-solid'
  | 'accent-outline'
  | 'primary-outline';

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  typeButton?: TypeButton;
  size?: 'default' | 'small';
  children?: ReactNode;
  fullWidth?: boolean;
  text?: string;
  isLoading?: boolean;
  img?: FC<SVGProps<SVGSVGElement>>;
  imgLocation?: 'right' | 'left';
  imgSize?: string;
  active?: boolean;
  capitalize?: boolean;
  uppercase?: boolean;
  isSquare?: boolean;
}

export const Button: FC<IButtonProps> = memo(props => {
  const {
    className,
    children,
    typeButton = 'accent',
    size = 'default',
    fullWidth,
    text,
    isLoading,
    img,
    imgLocation = 'left',
    imgSize = '24',
    active,
    capitalize = false,
    uppercase = true,
    isSquare = false,
    ...otherProps
  } = props;

  const currentClassName = useMemo(
    () =>
      classNames(
        cls.button,
        {
          [cls['full-width']]: fullWidth,
          [cls.active]: active,
          [cls.capitalize]: capitalize,
          [cls.uppercase]: uppercase,
          [cls.square]: isSquare,
        },
        [cls[typeButton], cls[size], cls[imgLocation], className],
      ),
    [
      fullWidth,
      active,
      capitalize,
      uppercase,
      isSquare,
      typeButton,
      size,
      imgLocation,
      className,
    ],
  );

  const currentTextFont: Variants = useMemo(() => {
    if (capitalize) {
      if (size === 'small') return 'button-small-v2';
      return 'button-default-v2';
    }
    if (size === 'small') return 'button-small';
    return 'button-default';
  }, [capitalize, size]);

  const currentColorForLoading = useMemo(() => {
    if (typeButton === 'primary' || typeButton === 'secondary')
      return '#010101';
    if (typeButton === 'link' || typeButton === 'accent-outline')
      return '#00C297';
    return '#FFFFFF';
  }, [typeButton]);

  return (
    <button
      type='button'
      className={classNames(currentClassName, {}, [])}
      {...otherProps}
    >
      {!!img && <Icon Svg={img} width={imgSize} height={imgSize} />}
      {isLoading ? (
        <CircleSpinner secondaryColor={currentColorForLoading} />
      ) : (
        <Text className={cls.text} variant={currentTextFont}>
          {text}
        </Text>
      )}
      {children}
    </button>
  );
});
